import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { Link } from "gatsby"
import React, { Fragment } from "react"

const GenericInlineAnchor = (href, children) => (
  <Link to={href}>{children}</Link>
)

export const Body = ({ data, truncate }) => {
  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        let {
          data: {
            target: {
              fields: { slug },
            },
          },
        } = node
        // TODO: remove hard coded locale
        return GenericInlineAnchor(`/${slug[`nl`]}`, children)
      },
      [BLOCKS.DOCUMENT]: (node, children) => {
        if (truncate) {
          return children.slice(0, 1)
        }
        return children
      },
    },
    renderText: text => {
      return text.split("\n").reduce((children, textSegement, index) => {
        return [...children, index > 0 && <br key={index} />, textSegement]
      }, [])
    },
  }

  return <Fragment>{documentToReactComponents(data, options)}</Fragment>
}

Body.defaultProps = {
  truncate: false,
  data: null,
}
